import React from 'react';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { PageProps, graphql } from 'gatsby';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../../localization/translations';
import { useLocaleContext } from '../../../../context/Locale';

type Props = PageProps & WithTranslation;

const Internship: React.FC<Props> = (props: Props) => {
  const { t, data } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('internship.interns.seo.title'),
    },
    page: {
      title: t('internship.interns.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: 'hero-careers-internship.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const getTable = () => {
    const list = t('internship.interns.john.questions', { returnObjects: true }) as {
      question: string;
      answer: string;
    }[];

    return (
      <table className="internship">
        <tbody>
          {list.map((item) => {
            return (
              <React.Fragment key={item.question}>
                <tr>
                  <th>{item.question}</th>
                </tr>
                <tr>
                  <td>
                    <p>
                      <Trans>{item.answer}</Trans>
                    </p>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    );
  };

  const imageUrl = (data as any).allFile.edges.find((edge: any) =>
    edge.node.name.includes(`-${activeLocaleTag}`)
  ).node.publicURL;

  return (
    <Layout options={layoutOptions} className="Careers Careers_intern">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <div className="Careers__generic-gap">
              <h2 className="h3">{t('internship.interns.john.headingThree')}</h2>
              {getTable()}
            </div>
          </section>
          <aside className="column column-33">
            <div className="Careers__generic-gap">
              <div className="img-container">
                <img src={imageUrl} alt={t('internship.interns.john.ariaImageLabel')} />
              </div>
            </div>
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allFile(
      filter: {
        relativePath: { regex: "/careers/interns/JohnMichaelClark-/" }
        ext: { eq: ".jpg" }
      }
    ) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`;

export default withTranslation(LocaleNameSpaceKeys.CAREERS)(Internship);
